import React from 'react';

const Digit = () => {
  return (
    <div>
      <div className="digits mt-5 mb-4">
        <div className="paste_btn">£</div>
        {[8, 1, 7, 4, 4, 6].map((data, index) => (
          <div  className="gray_btn" key={index}>
            {data}
          </div>
        ))}
      </div>
      <p className="total_txt">
        Total interest Sprive app users are on track to save*
      </p>
    </div>
  );
};

export default Digit;
